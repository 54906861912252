import { checkAccountDetailsComplete, checkContactInfoComplete } from '@storefront/util/checkout';
import { createReducer } from '@storefront/util/createReducer';
import type { ContextValue } from '@storefront/util/types';
import type { PaymentMethod } from '@storefront/util/types/payment-methods';
import type { ShippingOptions } from '@storefront/util/types/shipping-options';
import type { SetupIntent } from '@stripe/stripe-js';
import { type FC, type PropsWithChildren, createContext, useMemo, useReducer } from 'react';
import type { Bid, PaymentSession } from 'storefront-client';
import { useCart } from '../hooks/useCart';
import { useCustomer } from '../hooks/useCustomer';
import { useEnv } from '../hooks/useEnv';

export enum CheckoutStep {
  CONTACT_INFO = 'contactInfo',
  ACCOUNT_DETAILS = 'accountDetails',
  PAYMENT = 'payment',
  SUCCESS = 'success',
}

export interface CheckoutState {
  shippingDisabled: boolean;
  step: CheckoutStep;
  shippingOptions: ShippingOptions;
  paymentMethods: PaymentMethod[];
  paymentSessions: PaymentSession[];
  setupIntent: Pick<SetupIntent, 'id' | 'client_secret'> | null;
  userBids: Bid[];
}

export type CheckoutAction = {
  name: 'setStep';
  payload: CheckoutStep;
};

export type CheckoutContextValue = ContextValue<CheckoutState, CheckoutAction>;

export interface CheckoutProviderProps extends PropsWithChildren {
  data: {
    shippingOptions: ShippingOptions;
    paymentMethods: PaymentMethod[];
    paymentSessions: PaymentSession[];
    setupIntent: Pick<SetupIntent, 'id' | 'client_secret'> | null;
    userBids: Bid[];
  };
}

export const useNextStep = (_state: Omit<CheckoutState, 'step'>): CheckoutStep => {
  const { cart } = useCart();
  const { customer } = useCustomer();
  const isLoggedIn = !!customer?.id;

  const contactInfoComplete = useMemo(
    () => (!cart ? undefined : checkContactInfoComplete(cart, customer)),
    [cart, customer],
  );
  const accountDetailsComplete = useMemo(
    () => (!cart ? undefined : checkAccountDetailsComplete(cart)),
    [cart, isLoggedIn],
  );

  let nextStep = CheckoutStep.ACCOUNT_DETAILS;

  if (contactInfoComplete) nextStep = CheckoutStep.ACCOUNT_DETAILS;

  if (accountDetailsComplete) nextStep = CheckoutStep.PAYMENT;

  return nextStep;
};

// biome-ignore lint/suspicious/noExplicitAny: TBD,@typescript-eslint/no-unsafe-argument
export const CheckoutContext = createContext<CheckoutContextValue>(null as any);

const actionHandlers = {
  setStep: (state: CheckoutState, step: CheckoutStep) => {
    return { ...state, step };
  },
};

export const reducer = createReducer<CheckoutState, CheckoutAction>({
  actionHandlers,
});

export const CheckoutProvider: FC<CheckoutProviderProps> = ({ data, ...props }) => {
  const { env } = useEnv();
  const shippingDisabled = !!env.DISABLE_SHIPPING;
  const initialStep = useNextStep({
    ...data,
    shippingDisabled,
  });

  const initialState = {
    shippingDisabled,
    step: initialStep,
    shippingOptions: [],
    paymentMethods: [],
    paymentSessions: [],
    setupIntent: null,
    userBids: [],
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return <CheckoutContext.Provider value={{ state: { ...state, ...data }, dispatch }} {...props} />;
};
