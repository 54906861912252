import { useFetchers } from '@remix-run/react';
import { useRootLoaderData } from '@storefront/ui-components/hooks/useRootLoaderData';
import type { Cart as MedusaCart } from 'storefront-client';
import { useStorefront } from './useStorefront';

export type Cart = MedusaCart & { emailExists?: boolean; open?: boolean };

export const useCart = () => {
  const { state, actions } = useStorefront();
  const data = useRootLoaderData();
  const fetchers = useFetchers();
  const cartFetchers = fetchers.filter((f) => f.data?.cart);
  const removingLineItemFetchers = fetchers.filter(
    (f) => f.formData?.get('subaction') === 'deleteItem' && f.formData?.get('lineItemId'),
  );
  const cart = data?.cart as Cart;

  let isAddingItem = false;

  let isRemovingItemId: FormDataEntryValue | null | undefined = null;
  const cartFetcher = cartFetchers[cartFetchers.length - 1];
  const removingLineItemFetcher = removingLineItemFetchers[removingLineItemFetchers.length - 1];

  if (cartFetcher && cartFetcher.formMethod === 'POST' && ['loading', 'submitting'].includes(cartFetcher.state))
    isAddingItem = true;

  if (removingLineItemFetcher && ['loading', 'submitting'].includes(removingLineItemFetcher.state))
    isRemovingItemId = removingLineItemFetcher.formData?.get('lineItemId');

  return {
    cart,
    isAddingItem,
    isRemovingItemId,
    cartDrawerOpen: state.cart.open,
    toggleCartDrawer: actions.toggleCartDrawer,
  };
};
